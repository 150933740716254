<template>
	<div class="menu_manage">
		<el-card>
			<el-row class="left mb30">
				<el-col :span="24">
					<el-form :inline="true" class="demo-form-inline">
						<el-form-item label="菜单名称:">
							<el-input v-model="menu_name" placeholder="请输入菜单名称" clearable></el-input>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
							<el-button type="default" @click="reset" icon="el-icon-refresh">重置</el-button>
						</el-form-item>
					</el-form>
				</el-col>
				<el-col :span="24">
					<el-button type="primary" size="mini" icon="el-icon-plus" @click="addMenu">添加</el-button>
					<el-button type="default" size="mini" @click="openTable">展开/折叠</el-button>
					<el-button type="default" size="mini" icon="el-icon-refresh-right">刷新</el-button>
				</el-col>
			</el-row>

			<el-table :data="menuList" ref="menuRef" style="width: 100%; margin-bottom: 20px" height="300" row-key="menu_id" border :default-expand-all="isOpen" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
				<el-table-column label="菜单名称" width="150">
					<template slot-scope="scope">
						<i :class="scope.row.menu_icon"></i>&nbsp;{{scope.row.menu_name}}
					</template>
				</el-table-column>
				<el-table-column label="路由地址" prop="menu_luyou" width="130"></el-table-column>
				<el-table-column label="组件路径" prop="menu_zujian" width="130"></el-table-column>
				<el-table-column label="权限标识" prop="menu_auth" width="160"></el-table-column>
				<el-table-column label="排序" prop="menu_sort"></el-table-column>
				<el-table-column label="状态" width="130">
					<template slot-scope="scope">
						<div>{{ scope.row.is_show == 0 ? "显示" : "隐藏" }}</div>
					</template>
				</el-table-column>
				<el-table-column label="创建时间" prop="add_time" width="180"></el-table-column>
				<el-table-column label="操作" width="250" fixed="right">
					<template slot-scope="scope">
						<div>
							<el-button type="primary" size="mini" @click="addMenu(scope.row)">添加</el-button>
							<el-button type="primary" size="mini" @click="update(scope.row)">修改</el-button>
							<el-button type="danger" size="mini" @click="delMenu(scope.row)">删除</el-button>
						</div>
					</template>
				</el-table-column>
			</el-table>
		</el-card>
		<!-- 对话框 -->
		<el-dialog :title="title" :visible.sync="dialogVisible" width="700px" @close="cancel">
			<el-form ref="menuFormRef" :rules="rules" :model="menuForm" label-width="100px" class="left">
				<el-row>
					<el-col :span="24">
						<el-form-item label="上级菜单">
							<treeselect v-model="menuForm.parent_id" :options="menuList" :normalizer="normalizer" :show-count="true" placeholder="请选择上级菜单" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="菜单名称" prop="menu_name">
							<el-input v-model="menuForm.menu_name" placeholder="请输入菜单名称" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="菜单图标" prop="menu_icon">
							<el-input v-model="menuForm.menu_icon" placeholder="请输入菜单图标" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item prop="menu_luyou">
							<span slot="label">
				                <el-tooltip content="访问的路由地址，如：`user`，如外网地址需内链访问则以`http(s)://`开头" placement="top">
				                	<i class="el-icon-question"></i>
				                </el-tooltip>路由地址
			              	</span>
							<el-input v-model="menuForm.menu_luyou" placeholder="请输入路由地址" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item prop="menu_zujian">
							<span slot="label">
				                <el-tooltip content="访问的组件路径，如：`system/user/index`，默认在`views`目录下" placement="top">
				                	<i class="el-icon-question"></i>
				                </el-tooltip>组件路径
							</span>
							<el-input v-model="menuForm.menu_zujian" placeholder="请输入组件路径" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item prop="menu_auth">
							<el-input v-model="menuForm.menu_auth" placeholder="请输入权限标识" maxlength="100" />
							<span slot="label">
				                <el-tooltip content="控制器中定义的权限字符，如：@PreAuthorize(`@ss.hasPermi('system:user:list')`)" placement="top">
				                	<i class="el-icon-question"></i>
				                </el-tooltip>权限标识
							</span>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="显示排序" prop="menu_sort">
							<el-input-number v-model="menuForm.menu_sort" controls-position="right" :min="1" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item>
							<span slot="label">
				                <el-tooltip content="选择隐藏则路由将不会出现在侧边栏，也不能被访问" placement="top">
				                	<i class="el-icon-question"></i>
				                </el-tooltip>菜单状态
							</span>
							<el-radio-group v-model="menuForm.is_show">
								<el-radio :label="0">显示</el-radio>
								<el-radio :label="1">隐藏</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div class="right">
				<el-button type="primary" @click="submitForm">确 定</el-button>
				<el-button @click="cancel">取 消</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	let that;
	let timer = null;
	import Treeselect from "@riophae/vue-treeselect";
	import "@riophae/vue-treeselect/dist/vue-treeselect.css";
	export default {
		components: {
			Treeselect
		},
		data() {
			return {
				menu_name: "",
				isOpen: true,
				menuList: [],
				dialogVisible: false,
				title: '',
				treeProps: {
					children: "children",
					label: "label",
				},
				menuForm: {
					parent_id: 0,
					menu_name: '',
					menu_icon: '',
					menu_sort: 1,
					menu_luyou: '',
					menu_zujian: '',
					menu_auth: '',
					is_show: 0
				},
				rules: {
					menu_name: [{
						required: true,
						message: "菜单名称不能为空",
						trigger: "blur"
					}],
					menu_icon: [{
						required: true,
						message: "菜单图标不能为空",
						trigger: "blur"
					}],
					menu_sort: [{
						required: true,
						message: "菜单顺序不能为空",
						trigger: "blur"
					}],
				}
			};
		},
		created() {
			that = this;
			if(!this.$store.state.userInfo) {
				// 防止未登录
				this.$router.push({
					path: '/login'
				})
			} else {
				this.getMenuList();
			}
		},
		methods: {
			getMenuList() {
				var url = 'menu/menu_list';
				let params = {};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.menuList = res.list;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			//查询
			search() {
				this.getMenuList();
			},
			// 重置
			reset() {
				this.menu_name = "";
				this.getMenuList();
			},
			//折叠
			openTable() {
				this.isOpen = !this.isOpen;
				this.fold(this.menuList, this.isOpen);
			},
			fold(arr, isOpen) {
				arr.forEach((i) => {
					this.$refs.menuRef.toggleRowExpansion(i, isOpen);
					if(i.children) {
						this.fold(i.children, isOpen);
					}
				});
			},
			//添加菜单
			addMenu(row) {
				if(row != null && row.menu_id) {
					this.menuForm.parent_id = row.menu_id;
				} else {
					this.menuForm.parent_id = undefined;
				}
				this.title = '添加菜单'
				this.dialogVisible = true
			},
			//转换菜单数据结构
			normalizer(node) {
				if(node.children && !node.children.length) {
					delete node.children;
				}
				return {
					id: node.menu_id,
					label: node.menu_name,
					children: node.children,
				};
			},
			submitForm() {
				this.$refs.menuFormRef.validate(value => {
					if(!value) return
					if(timer != null) {
						clearTimeout(timer);
					}
					timer = setTimeout(() => {
						var url = 'menu/addOrUpdate';
						let params = that.menuForm;
						that.fd_post(url, params).then((res) => {
							if(res.status) {
								that.dialogVisible = false;
								that.$refs.menuFormRef.resetFields();
								that.$message.success('操作成功');
								that.getMenuList();
							} else {
								that.$message.error(res.msg);
							}
						}).catch((err) => {
							that.$message.error('网络错误');
						});
					}, 300);
				})
			},
			cancel() {
				this.dialogVisible = false;
				this.reset();
			},
			reset() {
				this.menuForm = {
					parent_id: 0,
					menu_name: '',
					menu_icon: '',
					menu_sort: 1,
					menu_luyou: '',
					menu_zujian: '',
					menu_auth: '',
					is_show: 0
				}
			},
			update(item) {
				this.title = '修改菜单';
				this.menuForm = {
					menu_id: item.menu_id,
					parent_id: item.parent_id,
					menu_name: item.menu_name,
					menu_icon: item.menu_icon,
					menu_sort: item.menu_sort,
					menu_luyou: item.menu_luyou,
					menu_zujian: item.menu_zujian,
					menu_auth: item.menu_auth,
					is_show: item.is_show
				}
				this.dialogVisible = true;
			},
			delMenu(item) {
				this.$confirm('你确定要删除吗,此操作将永久删除该条数据?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					if(timer != null) {
						clearTimeout(timer);
					}
					timer = setTimeout(() => {
						var url = 'menu/deleteMenu';
						let params = {
							menu_id: item.menu_id
						};
						that.fd_post(url, params).then((res) => {
							if(res.status) {
								that.$message.success("操作成功");
								that.getMenuList();
							} else {
								that.$message.error(res.msg);
							}
						}).catch((err) => {
							that.$message.error('网络错误');
						});
					}, 300);
				}).catch(() => {
					this.$message.info('已取消删除');
				});
			}
		},
	};
</script>

<style lang="scss" scoped>
	@import "./Menu_manage.scss";
</style>